/**
 * Create by zhuzhaoqing
 * Description: permission
 * Date: 2023/3/31 16:05
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 通过当级查询支付维度
export const selectByDimensionType = (data: any) =>
  request('/payAdminServer/payDimension/selectByDimensionType', data, 'GET', null)

// 通过上级维度编码查询支付维度
export const selectByParentDimensionCode = (data: any) =>
  request('/payAdminServer/payDimension/selectByParentDimensionCode', data, 'GET', null)

// 支付维度表信息(通过dbt_id查询所有pkg下的维度)
export const selectDimensionByDbtId = (data: any) =>
  request('/payAdminServer/payDimension/selectDimensionByDbtId', null, 'POST_JSON', data)

// 收入看板-收入明细数据-维度表
export const selectDimensionChart = (data: any) =>
  request('/payAdminServer/purchaseIncome/selectDimensionChart', null, 'POST_JSON', data)

// 收入看板-收入明细数据-趋势图-累计图
export const selectTrendChart = (data: any) =>
  request('/payAdminServer/purchaseIncome/selectTrendChart', null, 'POST_JSON', data)

// 通过工作室查询 dbtId
export const selectDbtIdByStudioId = (data: any) =>
  request('/payAdminServer/payDimension/selectDbtIdByStudioId', null, 'POST_JSON', data)

// exportDimensionChart
export const exportDimensionChart = (data: any) =>
  request('/payAdminServer/purchaseIncome/exportDimensionChart', null, 'POST_DOWNLOAD', data)

// 收入看板/付费事件看板-选择应用后过滤器下拉查询
export const queryEventFilters = (data: any) =>
  request('/payAdminServer/paid/event/filters', null, 'POST_JSON', data)

// 付费事件看板-列表查询
export const queryEventResult = (data: any) =>
  request('/payAdminServer/paid/event/table', null, 'POST_JSON', data)

// 付费事件看板-echart查询
export const queryEchartResult = (data: any) =>
  request('/payAdminServer/paid/event/result', null, 'POST_JSON', data)

// 付费事件看板-导出
export const exportEventResult = (data: any) =>
  request('/payAdminServer/paid/event/export', null, 'POST_DOWNLOAD', data)
