// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询订单列表
export const queryOrderList = (data: any) => request('/payAdminServer/order/list', null, 'POST_JSON', data)

//手动复送接口
export const resendOrder = (data: any) => request('/payAdminServer/order/fix/update', null, 'POST_JSON', data)

//查询复送接口
export const queryResendOrder = (data: any) => request('/payAdminServer/order/fix/get', null, 'POST_JSON', data)

//导出订单列表
export const exportOrderList = (data: any) => request('/payAdminServer/order/export', null, 'POST_DOWNLOAD', data)

// 提交退款
export const refundSubmit = (data: any) => request('/payAdminServer/order/refund/submit', null, 'POST_JSON', data)

// 查询退款订单
export const queryRefundList = (data: any) => request('/payAdminServer/order/refund/approval/list', null, 'POST_JSON', data)

// 重新提交退款
export const retryRefundSubmit = (data: any) => request('/payAdminServer/order/refund/update', null, 'POST_JSON', data)

// 重新提交退款
export const retryRefundApi = (data: any) => request('/payAdminServer/order/refund/retry', null, 'POST_JSON', data)
